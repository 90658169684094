import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { settingActions } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import * as StringTool from '../store/StringTool';

export default function NavViewMobile(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function logoTap() {
        window.location.href = window.location.origin;
    }

    function menuTap() {
        dispatch(settingActions.setShowMenu(true));
    }

    function languageTap() {
        dispatch(settingActions.setShowLanguage(true));
    }

    return (
        <Stack sx={{
            width: 1,
            height: '50px',
            bgcolor: '#000000cc',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 99,
            flexDirection: 'row',
            alignItems: 'center',
        }}>

            <Box
                onClick={menuTap}
                component={'img'}
                src={getImgPath('menu_white.png')}
                sx={{
                    height: 0.5,
                    ml: 3,
                    cursor: 'pointer',
                }} />

            <Box
                onClick={logoTap}
                component={'img'}
                src={settingData.navIndex == 0 ? getImgPath(`logo_off_${settingData.region}.png`) : getImgPath(`logo_on_${settingData.region}.png`)}
                sx={{
                    left: '30px',
                    height: 0.6,
                    pt: 1,
                    ml: 2,
                    cursor: 'pointer',
                }} />

            <Box sx={{ flex: 1 }} />

            <Box
                onClick={() => {
                    StringTool.shareTTTap();
                }}
                component={'img'}
                src={getImgPath('btn_tt.png')}
                sx={{
                    display: settingData.region == 'jp' || settingData.region == 'kr' ? 'flex' : 'none',
                    height: 0.5,
                    mr: 3,
                    cursor: 'pointer',
                }} />

            <Box
                onClick={() => {
                    languageTap();
                }}
                component={'img'}
                src={getImgPath('language.png')}
                sx={{
                    display: settingData.region == 'en' ? 'flex' : 'none',
                    height: 0.5,
                    mr: 3,
                    cursor: 'pointer',
                }} />

            <Box sx={{
                height: '1px',
                width: 1,
                bgcolor: '#5D5A6099',
                position: 'absolute',
                bottom: 0,
            }} />

        </Stack>
    );
}
