import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import GiftCodeView from '../component/GiftCodeView';

export default function GiftCodePage(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            alignItems: 'center',
        }}>
            <Box
                component={'img'}
                src={settingData.isMobile ? getImgPath('mobile/part4.jpg') : getImgPath('part8.jpg')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: 2,
                    objectFit: 'fill',
                }} />

            <Stack sx={{
                position: 'absolute',
                zIndex: 3,
                width: 1,
                height: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <GiftCodeView hideClose={true}/>
            </Stack>
            
        </Stack>
    )
}