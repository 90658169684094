import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { settingActions } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getImgPath } from '../store/StringTool';

export default function MobileMenuLanguage(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function itemTap(l) {
        dispatch(settingActions.setLanguage(l));
        dispatch(settingActions.setShowLanguage(false));
    }

    return (
        <Stack sx={{
            width: window.innerWidth * 0.7,
            height: window.innerHeight,
            bgcolor: 'black',
        }}>

            <Box component={'img'}
                onClick={props.closeLanguage}
                src={getImgPath('close_white.png')}
                sx={{
                    width: '25px',
                    mt: 2,
                    mr: 3,
                    mb: 5,
                    alignSelf: 'flex-end'
                }} />

            <Stack>
                <Item title='English' value='en' itemTap={itemTap} />
                <Item title='简体中文' value='cn' itemTap={itemTap} />
                <Item title='繁體中文' value='tw' itemTap={itemTap} />
            </Stack>

        </Stack>
    );
}

function Item(props) {
    const settingData = useSelector(state => state.settingData);
    return (
        <Stack onClick={() => props.itemTap(props.value)}>
            <Typography sx={{
                color: settingData.lang == props.value ? 'white' : 'gray',
                fontFamily: 'us',
                fontSize: 18,
                height: '70px',
                lineHeight: '70px',
                pr: 5,
                pl: 5,
            }}>
                {props.title}
            </Typography>
            <Box sx={{
                width: 1,
                height: '1px',
                bgcolor: '#C9CDD140',
            }} />
        </Stack>
    );
}