
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';


export default function StoryPage(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function playTap() {
        dispatch(settingActions.showVideoAlert(true));
    }

    function getContent(l) {
        if (l == 'en') {
            return `
            <p>What is Street Fighter?</p>
<p>There's Always Someone Stronger</p>
<p>Street Fighter can be summed up in one phrase, "There's Always Someone Stronger".</p>
<p>The series and its history are built upon the glorious victories and stunning defeats of its characters and players.</p>
<p>In Street Fighter: Duel</p>
<p>Everyone's favorite fighters are back!</p>
<p>An astonishing conspiracy is about to be revealed! Join Ryu and Ken on their journey to the Fighting Tournament, and take this adventure. Clear the missions, and become the strongest!</p>

            `

        } else if (l == 'jp') {

            return `
            <p>「ストリートファイター」とは？</p>
            <p>俺より強い奴に会いに行く</p>
            <p>「ストリートファイター」は「俺より強いやつに会いに行く」をスローガンに掲げたゲーム。</p>
            <p>「ストリートファイター」は作品のキャラクターとプレイヤーの栄光と挫折とともに、対戦格闘ゲームの歴史を築いてきた。</p>
            <p>最新のスマホゲーム「ストリートファイター：デュエル」では、</p>
            <p>歴代の人気ファイターたちが続々登場！</p>
            <p>世界を危機に晒す陰謀が動き出そうとしている！リュウやケンと共に格闘大会に挑み、新たな冒険へ旅立とう！数々の難関ミッションを攻略し、最強のファイターを目指せ！</p>
            `

        } else if (l == 'kr') {

            return `
            <p>스트리트 파이터란?</p>
<p>더 강한 사람은 늘 있기 마련</p>
<p>스트리트 파이터를 한마디로 정의하자면, &ldquo;더 강한 사람은 늘 있기 마련&rdquo;</p>
<p>이 시리즈와 역사는 캐릭터와 플레이어의 영광스러운 승리와 처절한 패배를 바탕으로 만들어졌습니다.</p>
<p>최신작 스트리트 파이터: 듀얼이 모바일로!</p>
<p>원작 격투가들의 화려한 귀환</p>
<p>놀라운 음모가 지금 바로 밝혀진다! 류, 켄과 한 팀이 되어 격투 대회에 참가하고 흥미진진한 모험을 떠나자. 스토리를 클리어하고 최강자가 되어라!</p>

            `
        } else if (l == 'cn') {
            return `
                
            <p>什么是街霸</p>
<p>总有更强大的人</p>
<p>街霸可以用一句话来概括&ldquo;总有更强大的人&rdquo;</p>
<p>该系列及其历史建立在其角色和玩家的光荣胜利和惊人失败之上。</p>
<p>在最新作 Street Fighter: Duel手游里，</p>
<p>經典格鬥家全數回歸，</p>
<p>一場驚人的陰謀即將揭開！加入Ryu和Ken的隊伍，一同出發前往格鬥大賽，加入精彩的冒險。過關劇情地圖，成為最強王者！</p>

            `
        }
        else if (l == 'tw') {
            return `
            
            <p>什麼是街霸</p>
            <p>總有更強大的人</p>
            <p>街霸可以用一句話來概括&ldquo;總有更強大的人&rdquo;</p>
            <p>該系列及其歷史建立在其角色和玩家的光榮勝利和驚人失敗之上。</p>
            <p>在最新作 Street Fighter: Duel手遊裏，</p>
            <p>經典格鬥家全數回歸，</p>
            <p>一場驚人的陰謀即將揭開！加入Ryu和Ken的隊伍，一同出發前往格鬥大賽，加入精彩的冒險。過關劇情地圖，成為最強王者！</p>

            `
        }
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            alignItems: 'center',
            WebkitFilter: settingData.showVideoAlert ? 'brightness(0%)' : 'brightness(100%)',
            filter: settingData.showVideoAlert ? 'brightness(0%)' : 'brightness(100%)',
        }}>
            <Box
                component={'img'}
                src={getImgPath('part4.jpg')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: 2,
                    objectFit: 'fill',
                }} />

            <Stack sx={{
                position: 'absolute',
                zIndex: 3,
                width: 1,
                height: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box
                    component={'img'}
                    src={getImgPath('title4.png')}
                    sx={{
                        height: settingData.isMobile ? '70px' : '90px',
                        mt: settingData.isMobile ? 10 : 16,
                    }} />

                <Box
                    onClick={playTap}
                    component={'img'}
                    src={getImgPath('play.png')}
                    sx={{
                        height: settingData.isMobile ? '35px' : '45px',
                        cursor: 'pointer',
                        transitionDuration: '0.3s',
                        my: 2,
                        '&:hover': {
                            transform: 'scale(1.1)',
                        },
                    }} />


                <Box
                    component={'div'}
                    sx={{
                        fontSize: settingData.isMobile ? 14 : 22,
                        color: 'white',
                        width: settingData.isMobile ? 0.9 : '700px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        mb: 5
                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                          ${getContent(settingData.lang)}

                            `
                    }} />

            </Stack>

        </Stack>
    )
}