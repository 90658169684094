import { createSlice } from '@reduxjs/toolkit';
import * as StringTool from './StringTool';
import LangJson from './lang.json';
import $ from 'jquery';

const LANG = StringTool.getLang();
const REGION = StringTool.getRegion();

export const settingObj = {
    navIndex: 0,
    mousewheel: true,
    lang: LANG,
    region: REGION,
    isFightting: false,
    adPlatform: '',
    verify_cid: null,
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        navIndex: 0,
        characterWrapperIndex: 0,
        lang: LANG,
        region: REGION,
        langDic: getLangDic(LANG),
        showVideoAlert: false,
        showRegisterAlert: false,
        preProgress: '50k',
        characterName: 'Blanka',
        mousewheel: true,
        type: 'type_all',
        detailName: 'Chun-Li',
        isMobile: false,
        showMenu: false,
        isFightting: false,
        adPlatform: '',
        showLanguage: false,
        showToast: null,
        showLoading: false,
    },
    reducers: {

        showLoading: (state, action) => {
            state.showLoading = action.payload;
        },

        showToast: (state, action) => {
            state.showToast = action.payload;
        },

        setNavIndex: (state, action) => {
            state.navIndex = action.payload;
            settingObj.navIndex = action.payload;
        },

        showVideoAlert: (state, action) => {
            state.showVideoAlert = action.payload;
        },

        showRegisterAlert: (state, action) => {
            state.showRegisterAlert = action.payload;
            state.mousewheel = !action.payload;
            settingObj.mousewheel = !action.payload;
        },

        updateCodeData: (state, action) => {
            var url = StringTool.getAPIHost() + "/1/vc/code?t=" + new Date().getTime();
            $.get(url, (res) => {
                if (res != null && res != NaN && res.code == 0) {
                    settingObj.verify_cid = res.data.cid;
                    $('#verify_image').attr('src', res.data.code);
                }
            });
        },

        setLanguage: (state, action) => {
            state.lang = action.payload;
            settingObj.lang = action.payload;
            state.langDic = getLangDic(action.payload);
            StringTool.saveLocalString('local_lang', action.payload);
        },

        setRegion: (state, action) => {
            state.region = action.payload;
            settingObj.region = action.payload;
        },

        setPreProgress: (state, action) => {
            state.preProgress = action.payload;
        },

        setCharacterName: (state, action) => {
            state.characterName = action.payload;
        },

        setCharacterWrapperIndex: (state, action) => {
            state.characterWrapperIndex = action.payload;
        },

        setMousewheel: (state, action) => {
            state.mousewheel = action.payload;
            settingObj.mousewheel = action.payload;
        },

        setType: (state, action) => {
            state.type = action.payload;
        },

        setDetailName: (state, action) => {
            state.detailName = action.payload;
        },

        setIsMobile: (state, action) => {
            state.isMobile = action.payload;
        },

        setShowMenu: (state, action) => {
            state.showMenu = action.payload;
        },

        setIsFightting: (state, action) => {
            state.isFightting = action.payload;
            settingObj.isFightting = action.payload;
        },

        setAdPlatform: (state, action) => {
            state.adPlatform = action.payload;
            settingObj.adPlatform = action.payload;
        },

        setShowLanguage: (state, action) => {
            state.showLanguage = action.payload;
        },

    }
});

function getLangDic(l) {
    for (const key in LangJson) {
        if (key == l) {
            return LangJson[l];
        }
    }
    return null;
}

export const settingActions = settingSlice.actions

export default settingSlice.reducer
