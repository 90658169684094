
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import PageFooter from '../component/PageFooter';
import PageFooterMobile from '../component/PageFooterMobile';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as StringTool from '../store/StringTool';

export default function CommunityPage(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            alignItems: 'center',
        }}>
            <Box
                component={'img'}
                src={settingData.isMobile ? getImgPath('mobile/part4.jpg') : getImgPath('part8.jpg')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: 2,
                    objectFit: 'fill',
                }} />

            <Stack sx={{
                position: 'absolute',
                zIndex: 3,
                width: 1,
                height: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box
                    component={'img'}
                    src={getImgPath('title8.png')}
                    sx={{
                        height: settingData.isMobile ? '50px' : '100px',
                    }} />

                <Stack sx={{
                    flexDirection: 'row',
                    my: 12,
                }}>
                    <Box
                        onClick={() => {
                            StringTool.shareTTTap();
                        }}
                        component={'img'}
                        src={getImgPath('part8/icon_1.png')}
                        sx={{
                            display: settingData.region == 'jp' || settingData.region == 'kr' ? 'flex' : 'none',
                            height: settingData.isMobile ? '65px' : '120px',
                            mx: settingData.isMobile ? 3 : 10,
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.2)',
                            },
                        }} />

                    <Box
                        onClick={() => {
                            StringTool.shareFBTap();
                        }}
                        component={'img'}
                        src={getImgPath('part8/icon_2.png')}
                        sx={{
                            display: settingData.region == 'en' ? 'flex' : 'none',
                            height: settingData.isMobile ? '65px' : '120px',
                            mx: settingData.isMobile ? 3 : 10,
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.2)',
                            },
                        }} />

                    <Box
                        onClick={() => {
                            StringTool.shareOneTap();
                        }}
                        component={'img'}
                        src={getImgPath('part8/icon_5.png')}
                        sx={{
                            display: settingData.region == 'kr' ? 'flex' : 'none',
                            height: settingData.isMobile ? '65px' : '120px',
                            mx: settingData.isMobile ? 3 : 10,
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.2)',
                            },
                        }} />
                </Stack>

            </Stack>

            {!settingData.isMobile && <PageFooter />}
            {settingData.isMobile && <PageFooterMobile />}

        </Stack>
    )
}