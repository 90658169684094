import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useNavigate } from "react-router-dom";


export default function PageFooterMobile(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [ft, setFt] = React.useState(12);

    function privacyTap() {
        window.open(`${window.location.origin}/privacy`);
    }

    function termsTap() {
        window.open(`${window.location.origin}/terms`);
    }

    function jpTap0() {
        window.open(`${window.location.origin}/financial`);
    }

    function jpTap1() {
        window.open(`${window.location.origin}/special`);
    }
    
    return (
        <Stack sx={{
            position: props.noAbaolute ? 'auto' : 'absolute',
            bottom: 0,
            zIndex: 10,
            width: 1,
            bgcolor: '#0F1114ee',
            zIndex: 99,
        }}>
            {/* 文字 */}
            <Stack sx={{
                color: '#999999',
                flexDirection: 'row',
                justifyContent: 'center',
                flexWrap: 'wrap',
                pt: 1,
            }}>


                <Typography
                    onClick={privacyTap}
                    sx={{
                        fontSize: ft,
                        cursor: 'pointer',
                        borderColor: '#999999',
                        fontWeight: 'bold',
                        '&:hover': {
                            borderBottom: 1,
                        },
                    }}>
                    {settingData.langDic['bottom0']}
                </Typography>

                <Typography sx={{
                    mx: '5px',
                    fontSize: ft,
                }}>
                    /
                </Typography>

                <Typography
                    onClick={termsTap}
                    sx={{
                        fontSize: ft,
                        cursor: 'pointer',
                        borderColor: '#999999',
                        fontWeight: 'bold',
                        '&:hover': {
                            borderBottom: 1,
                        },
                    }}>
                    {settingData.langDic['bottom1']}
                </Typography>

                <Typography sx={{
                    mx: '5px',
                    fontSize: ft,
                }}>
                    /
                </Typography>

                {/* 日本多两个 */}
                {
                    settingData.region == 'jp' &&
                    <Stack sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                        <Typography
                            onClick={jpTap0}
                            sx={{
                                fontSize: ft,
                                cursor: 'pointer',
                                borderColor: '#999999',
                                fontWeight: 'bold',
                                '&:hover': {
                                    borderBottom: 1,
                                },
                            }}>
                            資金決済法に基づく
                        </Typography>

                        <Typography sx={{
                            mx: '5px',
                            fontSize: ft,
                        }}>
                            /
                        </Typography>

                        <Typography
                            onClick={jpTap1}
                            sx={{
                                fontSize: ft,
                                cursor: 'pointer',
                                borderColor: '#999999',
                                fontWeight: 'bold',
                                '&:hover': {
                                    borderBottom: 1,
                                },
                            }}>
                            特定商取引法に基づく
                        </Typography>

                    </Stack>
                }

            </Stack>


            <a style={{
                color: '#999999',
                fontSize: ft,
                fontWeight: 'bold',
                marginTop: '3px',
                marginBottom: '5px',
                textAlign: 'center',
            }} href={`mailto:${settingData.langDic['bottom_email']}`}>{settingData.langDic['bottom2']}</a>

            <Typography sx={{
                fontSize: ft,
                fontWeight: 'bold',
                color: '#999999',
                textAlign: 'center',
                mx: 3,
            }}>
                {settingData.langDic['bottom3']}
            </Typography>

            <Stack sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 1,
            }}>
                <Box
                    component={'img'}
                    src={getImgPath('logo_footer1.png')}
                    sx={{
                        height: '30px',
                        mx: '5px',

                    }} />
                <Box
                    component={'img'}
                    src={getImgPath('logo_footer2.png')}
                    sx={{
                        height: '30px',
                        mx: '5px',

                    }} />
                <Box
                    component={'img'}
                    src={getImgPath('logo_footer3.png')}
                    sx={{
                        height: '30px',
                        mx: '5px',
                    }} />

                <Box
                    component={'img'}
                    src={getImgPath('logo_footer4.png')}
                    sx={{
                        height: '28px',
                        ml: '5px',
                    }} />
            </Stack>

        </Stack>
    );
}