import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import HeroJson from '../store/hero.json';


export default function CharacterAllPageMobile() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const ary = ['Ryu', 'Ken', 'E.Honda', 'Ibuki', 'Makoto', 'Dudley', 'Gen', 'Dan', 'Juri', 'Chun-Li',
        'Dhalsim', 'Abel', 'C.Viper', 'Vega', 'Cammy', 'Dee-Jay', 'Cody', 'Guy', 'Guile', 'Blanka',
        'Zangief', 'Rufus', 'Feilong', 'T.Hawk', 'Adon', 'Rose', 'Decapre', 'Yun', 'Sakura', 'Yang',
        'Hakan', 'Elena', 'Rolento', 'Hugo', 'Poison'];

    function backTap() {
        dispatch(settingActions.setCharacterWrapperIndex(0));
        dispatch(settingActions.setMousewheel(true))
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            alignItems: 'center',
        }}>
            <Box
                component={'img'}
                src={getImgPath('part5/skin_orange.jpg')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: 2,
                    objectFit: 'cover',
                }} />


            {/* 导航 */}
            <Stack sx={{
                position: 'absolute',
                width: 1,
                top: '51px',
                zIndex: 2,
                flexDirection: 'row',
                alignItems: 'center',
            }}>

                <Box sx={{
                    width: 1,
                    height: '1px',
                    bgcolor: '#4B1515',
                    bottom: 0,
                    position: 'absolute',
                    zIndex: 3,
                }} />

                <Box
                    onClick={backTap}
                    component={'img'}
                    src={getImgPath('ico_back.png')}
                    sx={{
                        ml: 3,
                        my: 2,
                        width: '35px',
                        height: '35px',
                    }} />


                <Typography sx={{
                    color: '#B32228',
                    ml: 1,
                    fontSize: 18,
                    fontWeight: 'bold',
                }}>
                    {settingData.langDic['menu4']}
                </Typography>
            </Stack>

            {/* 类型 */}
            <Stack sx={{
                flexDirection: 'row',
                alignItems: 'center',
                position: 'absolute',
                zIndex: 2,
                top: '130px',
            }}>
                <MenuItem type='type_all' />
                <MenuItem type='type_0' />
                <MenuItem type='type_1' />
                <MenuItem type='type_2' />
                <MenuItem type='type_3' />
                <MenuItem type='type_4' />
                <MenuItem type='type_5' />
            </Stack>

            <Stack
                onMouseOver={() => {
                    dispatch(settingActions.setMousewheel(false))
                }}
                onMouseLeave={() => {
                    dispatch(settingActions.setMousewheel(true))
                }}
                sx={{
                    width: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,
                    position: 'absolute',
                    top: '210px',
                    height: `${window.innerHeight - 210}px`,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}>

                {ary.map((value, i) => {
                    return <RoleItem key={i} name={value} />
                })}
            </Stack>
        </Stack>
    );
}

function RoleItem(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function tap() {
        dispatch(settingActions.setDetailName(props.name));
        dispatch(settingActions.setCharacterWrapperIndex(2));
        dispatch(settingActions.setMousewheel(true));
    }

    return (
        <Stack
            onClick={tap}
            sx={{
                width: '75px',
                height: '120px',
                mx: '2px',
                my: '5px',
                transform: 'skew(-20deg)',
                overflow: 'hidden',
                border: 1,
                borderColor: settingData.type == HeroJson[props.name]['type'] ? 'white' : '#8E999E',
                bgcolor: '#000000aa',
            }}>
            <Box
                component={'img'}
                src={getImgPath(`all/${props.name}.png`)}
                sx={{
                    WebkitFilter: settingData.type == HeroJson[props.name]['type'] ? 'brightness(120%)' : 'brightness(70%)',
                    filter: settingData.type == HeroJson[props.name]['type'] ? 'brightness(120%)' : 'brightness(70%)',
                    cursor: 'pointer',
                    transform: 'skew(20deg)',
                    width: '135px',
                    height: 1,
                    ml: '-30px',
                    overflow: 'hidden',
                    objectFit: 'contain',
                }} />

            <Typography sx={{
                transform: 'skew(20deg)',
                position: 'absolute',
                bottom: 0,
                color: '#999999',
                backgroundSize: '100% 100%',
                fontSize: 9,
                width: 0.75,
                left: '-3px',
                pl: 1,
                fontWeight: 'bold',
                backgroundImage: `url(${getImgPath('part5/i_icon_namebg.png')})`,
            }}>
                {HeroJson[props.name][settingData.lang]['name'].toUpperCase()}
            </Typography>

        </Stack>
    );

}

function MenuItem(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function tap() {
        dispatch(settingActions.setType(props.type));
    }

    return (
        <Stack
            onClick={tap}
            sx={{
                WebkitTapHighlightColor: 'transparent',
                mx: '4px',
                transform: 'skew(-18deg)',
                width: window.innerWidth / 9,
                maxWidth: '60px',
                height: '70px',
                position: 'relative',
                borderBottom: 2,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                borderColor: props.type == settingData.type ? '#D92D32' : '',
                background: props.type == settingData.type ? 'linear-gradient(#1B0809 , #6B191C)' : 'linear-gradient(#310E0F , #22090A)',
            }}>

            <Box
                component={'img'}
                src={getImgPath(`type/${props.type}.png`)}
                sx={{
                    transform: 'skew(18deg)',
                    width: 1,

                }} />

        </Stack>
    );

}