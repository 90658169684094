
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';

export default function ContentPage(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [swp, setSwp] = React.useState(null);
    const imgAry = [1, 2, 3, 4, 5];

    function preTap() {
        if (swp) {
            swp.slidePrev();
        }
    }

    function nextTap() {
        if (swp) {
            swp.slideNext();
        }
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            alignItems: 'center',
        }}>
            <Box
                component={'img'}
                src={settingData.isMobile ? getImgPath('mobile/part4.jpg') : getImgPath('part6.jpg')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: 2,
                    objectFit: 'fill',
                }} />

            <Stack sx={{
                position: 'absolute',
                zIndex: 3,
                width: 1,
                height: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box
                    component={'img'}
                    src={getImgPath('title7.png')}
                    sx={{
                        height: settingData.isMobile ? '50px' : '100px',
                        mb: 3,
                        mt: 5,
                    }} />

                <Stack sx={{
                    flexDirection: 'row',
                    color: 'white',
                    alignItems: 'center',
                }}>

                    <Box
                        onClick={preTap}
                        component={'img'}
                        src={getImgPath('part7/i_left.png')}
                        sx={{
                            cursor: 'pointer',
                            height: settingData.isMobile ? '30px' : '110px',
                            mr: 2,
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            }
                        }} />


                    {/* 图片 */}
                    <Swiper style={{
                        zIndex: 3,
                        bgcolor: 'yellow',
                        width: settingData.isMobile ? window.innerWidth - 120 : window.innerWidth * 0.65,
                        minWidth: settingData.isMobile ? '200px' : '900px',
                    }}
                        loop={true}
                        slidesPerView={1}
                        modules={[Autoplay]}
                        centeredSlides={true}
                        slideToClickedSlide={true}

                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}

                        onSwiper={(swiper) => {
                            setSwp(swiper);
                        }}>

                        {imgAry.map((value, i) => {
                            return <SwiperSlide key={i}><PosterItem index={imgAry[i]} /></SwiperSlide>
                        })}
                    </Swiper>

                    <Box
                        onClick={nextTap}
                        component={'img'}
                        src={getImgPath('part7/i_right.png')}
                        sx={{
                            cursor: 'pointer',
                            height: settingData.isMobile ? '30px' : '110px',
                            ml: 2,
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            }
                        }} />


                </Stack>

            </Stack>

        </Stack>
    )
}

function PosterItem(props) {
    const settingData = useSelector(state => state.settingData);
    return (
        <Stack sx={{
            width: 1,
        }}>
            <Box
                component={'img'}
                src={settingData.isMobile ? getImgPath(`content/mobile_content_${settingData.lang}${props.index}.png`) : getImgPath(`content/content_${settingData.lang}${props.index}.png`)}
                sx={{
                    objectFit: 'contain',
                    width: 1,
                }} />
        </Stack>
    );
}