import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as StringTool from '../store/StringTool';
import PaymentButton from '../component/PaymentButton';

export default function HomePageMobile() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function playTap() {
        dispatch(settingActions.showVideoAlert(true));
    }

    function giftTap() {
        dispatch(settingActions.showRegisterAlert(true));
    }

    return (
        <Stack sx={{
            width: 1,
            height: 1,
            backgroundSize: '100% 100%',
            backgroundImage: `url(${getImgPath('mobile/part1.jpg')})`,
            justifyContent: 'center',
            alignItems: 'center',
            WebkitFilter: settingData.showVideoAlert ? 'brightness(0%)' : 'brightness(100%)',
            filter: settingData.showVideoAlert ? 'brightness(0%)' : 'brightness(100%)',
        }}>

            <Box
                component={'img'}
                src={getImgPath(`slogan_${settingData.region}.png`)}
                sx={{
                    width: '125%',
                    mt: '80px',
                }} />

            <Box sx={{ flex: 1 }} />

            <Box
                onClick={playTap}
                component={'img'}
                src={getImgPath('play.png')}
                sx={{
                    height: '40px',
                }} />

            <Stack sx={{
                flexDirection: 'row',
                mt: 3,
            }}>
                <Typography sx={{
                    fontSize: 30,
                    fontWeight: 'bold',
                    color: 'white',
                }}>
                    {settingData.langDic['home1']}
                </Typography>

                <Typography sx={{
                    fontSize: 30,
                    fontWeight: 'bold',
                    color: 'red',
                    ml: 1,
                }}>
                    {settingData.langDic['home2']}
                </Typography>
            </Stack>

            <Typography sx={{
                fontSize: 13,
                fontWeight: 'bold',
                color: 'white',
                display: 'none'
            }}>
                {settingData.langDic['home3']}
            </Typography>

            {/* 日本下载按钮 */}
            <Stack sx={{
                display: settingData.region == 'jp' ? 'flex' : 'none',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 3,
            }}>
                <Box
                    component={'img'}
                    src={getImgPath('red_left.png')}
                    sx={{
                        height: '60px',
                        mr: 1,
                    }} />

                <Box
                    onClick={() => {
                        StringTool.appleTap();
                    }}
                    component={'img'}
                    src={getImgPath(`btns/${settingData.region}_apple_release.png`)}
                    sx={{
                        width: 0.36,
                        mr: 1,
                        borderColor: '#cccccc',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }} />

                <Box
                    onClick={() => {
                        StringTool.googleTap();
                    }}
                    component={'img'}
                    src={getImgPath(`btns/${settingData.region}_google_release.png`)}
                    sx={{
                        width: 0.36,
                        borderColor: '#cccccc',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }} />

                <Box
                    component={'img'}
                    src={getImgPath('red_right.png')}
                    sx={{
                        height: '60px',
                        ml: 1,
                    }} />
            </Stack>


            {/* 韩国下载按钮 */}
            <Stack sx={{
                display: settingData.region == 'kr' ? 'flex' : 'none',
                alignItems: 'center',
                mt: 3,
            }}>
                <Box
                    onClick={() => {
                        StringTool.appleTap();
                    }}
                    component={'img'}
                    src={getImgPath(`btns/${settingData.region}_apple_release.png`)}
                    sx={{
                        height: '55px',
                        borderColor: '#cccccc',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }} />

                <Box
                    onClick={() => {
                        StringTool.googleTap();
                    }}
                    component={'img'}
                    src={getImgPath(`btns/${settingData.region}_google_release.png`)}
                    sx={{
                        height: '55px',
                        mt: 1,
                        borderColor: '#cccccc',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }} />

                <Box
                    onClick={() => {
                        StringTool.onestoreTap();
                    }}
                    component={'img'}
                    src={getImgPath(`btns/${settingData.region}_one_release.png`)}
                    sx={{
                        height: '55px',
                        mt: 1,
                        borderColor: '#cccccc',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }} />

            </Stack>

              {/* 东南亚下载按钮 */}
              <Stack sx={{
                display: settingData.region == 'en' ? 'flex' : 'none',
                alignItems: 'center',
                mt: 3,
            }}>
                <Box
                    onClick={() => {
                        StringTool.appleTap();
                    }}
                    component={'img'}
                    src={getImgPath(`btns/${settingData.region}_apple_release.png`)}
                    sx={{
                        height: '55px',
                        borderColor: '#cccccc',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }} />

                <Box
                    onClick={() => {
                        StringTool.googleTap();
                    }}
                    component={'img'}
                    src={getImgPath(`btns/${settingData.region}_google_release.png`)}
                    sx={{
                        height: '55px',
                        mt: 1,
                        borderColor: '#cccccc',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }} />

               <PaymentButton/>

            </Stack>


            <Box component={'img'}
                src={getImgPath('next.png')}
                sx={{
                    mt: 5,
                    mb: '100px',
                    height: '22px',
                    animation: 'scrollerAni 1.5s ease-in-out both infinite',
                }} />

            {/* 礼物 */}
            <Box
                onClick={giftTap}
                component={'img'}
                src={StringTool.getImgPath('right_float.png')}
                sx={{
                    zIndex: 99,
                    height: '50px',
                    position: 'absolute',
                    top: '0',
                    right: '10px',
                    bottom: 0,
                    margin: 'auto',
                }} />
        </Stack>

    )
}