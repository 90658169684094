import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel } from 'swiper/modules';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from './store/SettingSlice';
import $ from 'jquery';

import HomePage from './page/HomePage';
import PreRegister from './page/PreRegister';
import CharacterPageWrapper from './page/CharacterPageWrapper';
import NavView from './component/NavViw';
import StoryPage from './page/StoryPage';
import ContentPage from './page/ContentPage';
import * as StringTool from './store/StringTool';
import CommunityPage from './page/CommunityPage';

// pc 端
function App() {

  const dispatch = useDispatch();
  const settingData = useSelector(state => state.settingData);
  const [vHeight, setVHeight] = React.useState(window.innerHeight);
  const [swiper, setSwiper] = React.useState(null);
  let isProcessing = false;
  let swp = null;
  let resizeTimer;

  React.useEffect(() => {
    $(document).on("mousewheel", processScroll);
    $(window).on("resize", changeSize);
    dispatch(settingActions.setLanguage(StringTool.getLang()));
    dispatch(settingActions.setRegion(StringTool.getRegion()));
  }, []);

  function changeSize(e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      setVHeight(window.innerHeight)
    }, 250);
  }


  function processScroll(e) {
    if (isProcessing || !settingObj.mousewheel) {
      return;
    }
    const y = e.originalEvent.deltaY;
    if (y < 0) {
      isProcessing = true;
      if (swp) {
        swp.slidePrev();
      }
    } else if (y > 0) {
      isProcessing = true;
      if (swp) {
        swp.slideNext();
      }
    }

    setTimeout(() => {
      isProcessing = false;
    }, 700);
  }

  function navTap(index) {
    if (swiper) {
      swiper.slideTo(index);
    }
  }

  function giftTap(){
    dispatch(settingActions.showRegisterAlert(true));
  }

  return (
    <Stack sx={{
      width: 1,
      height: vHeight,
      minWidth: '1310px',
      minHeight: '700px',

    }}>

      <Swiper
        direction={'vertical'}
        slidesPerView={1}
        className="mySwiper"
        // mousewheel={settingData.mousewheel}
        // modules={settingData.mousewheel && [Mousewheel]}
        resistanceRatio={0}
        onSwiper={(sp) => {
          swp = sp;
          setSwiper(sp);
        }}

        onActiveIndexChange={(sp) => {
          dispatch(settingActions.setNavIndex(sp.activeIndex));
        }}

        style={{
          width: '100%',
          height: '100%',
        }}>

        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><HomePage /></SwiperSlide>
        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><StoryPage /></SwiperSlide>
        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><CharacterPageWrapper /></SwiperSlide>
        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><ContentPage /></SwiperSlide>
        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><CommunityPage /></SwiperSlide>

      </Swiper>

      <NavView navTap={navTap} />

      <Box
      onClick={giftTap}
        component={'img'}
        src={StringTool.getImgPath('right_float.png')}
        sx={{
          zIndex: 99,
          height: '80px',
          position: 'absolute',
          top: 0,
          right: '20px',
          bottom: 0,
          margin: 'auto',
          transitionDuration: '0.3s',
          cursor: 'pointer',
          '&:hover': {
            transform: 'scale(1.1)',
          }
        }} />

    </Stack>
  );
}

export default App;
