import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { settingActions } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import * as StringTool from '../store/StringTool';

export default function NavView(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    let timer;

    function logoTap() {
        window.location.href = window.location.origin;
    }

    function showLang() {
        clearTimeout(timer);
        dispatch(settingActions.setShowLanguage(true));
    }

    function hideLang() {
        timer = setTimeout(function () {
            dispatch(settingActions.setShowLanguage(false));
        }, 300);
    }

    return (
        <Stack sx={{
            width: 1,
            minWidth: '800px',
            height: '85px',
            bgcolor: '#00000099',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 99,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        }}>

            {/* 正式 */}
            {(!props.isFightting) &&
                <Stack sx={{ flexDirection: 'row' }}>
                    <NavItem index={0} title={settingData.langDic['menu1']} navTap={props.navTap} />
                    <NavItem index={1} title={settingData.langDic['menu3']} navTap={props.navTap} />
                    <NavItem index={2} title={settingData.langDic['menu4']} navTap={props.navTap} />
                    <NavItem index={3} title={settingData.langDic['menu5']} navTap={props.navTap} />
                    <NavItem index={4} title={settingData.langDic['menu6']} navTap={props.navTap} />
                </Stack>
            }

            {/* 正式 */}
            {(props.isFightting) &&
                <Stack sx={{ flexDirection: 'row' }}>
                    <NavItem index={0} title={settingData.langDic['menu1']} navTap={props.navTap} />
                    <NavItem index={1} title={settingData.langDic['menu4']} navTap={props.navTap} />
                </Stack>
            }

            <Box
                onClick={logoTap}
                component={'img'}
                src={settingData.navIndex == 0 ? getImgPath(`logo_off_${settingData.region}.png`) : getImgPath(`logo_on_${settingData.region}.png`)}
                sx={{
                    position: 'absolute',
                    left: '30px',
                    height: '65px',
                    top: '15px',
                    cursor: 'pointer',
                }} />

            <Box
                onClick={() => {
                    StringTool.shareTTTap();
                }}
                component={'img'}
                src={getImgPath('btn_tt.png')}
                sx={{
                    display: settingData.region == 'jp' || settingData.region == 'kr' ? 'flex' : 'none',
                    position: 'absolute',
                    right: '80px',
                    height: '35px',
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.2)',
                    }
                }} />

            {/* 语言选项 */}
            <Box
                onMouseOver={showLang}
                onMouseLeave={hideLang}
                component={'img'}
                src={getImgPath('language.png')}
                sx={{
                    display: settingData.region == 'en' ? 'flex' : 'none',
                    position: 'absolute',
                    right: '60px',
                    height: '30px',
                    cursor: 'pointer',
                }} />
            <LangDrawer showLang={showLang} hideLang={hideLang} />

            <Box sx={{
                height: '1px',
                width: 1,
                bgcolor: '#5D5A6099',
                position: 'absolute',
                bottom: 0,
            }} />

        </Stack>
    );
}

function NavItem(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function tap() {
        dispatch(settingActions.setNavIndex(props.index));
        if (props.navTap) {
            props.navTap(props.index);
        }
    }

    return (
        <Stack
            onClick={tap}
            sx={{
                mx: 2.5,
                height: 1,
                position: 'relative',
                alignItems: 'center',
                cursor: 'pointer',
                minWidth: '50px',
            }}>
            <Typography sx={{
                height: 1,
                lineHeight: '85px',
                fontSize: 18,
                fontWeight: 'bold',
                color: '#999999',
            }}>
                {props.title}
            </Typography>

            <Box sx={{
                visibility: props.index == settingData.navIndex ? 'flex' : 'hidden',
                height: '3px',
                width: '100%',
                bgcolor: '#D92D32',
                position: 'absolute',
                bottom: '1px',
            }} />

        </Stack>

    );
}


function LangDrawer(props) {
    const settingData = useSelector(state => state.settingData);
    return (
        <Stack
            onMouseOver={props.showLang}
            onMouseLeave={props.hideLang}
            sx={{
                display: settingData.showLanguage ? 'flex' : 'none',
                zIndex: 99,
                bgcolor: '#00000099',
                position: 'absolute',
                top: '83px',
                right: '20px',
                width: '110px',
                border: 1,
                borderColor: '#5D5A6099',
            }}>
            <LangItem value='en' title='English' />
            <LangItem value='cn' title='简体中文' />
            <LangItem value='tw' title='繁體中文' />
        </Stack>
    );
}


function LangItem(props) {
    const settingData = useSelector(state => state.settingData);
    const dispatch = useDispatch();

    function tap() {
        dispatch(settingActions.setLanguage(props.value))
    }

    return (
        <Typography
            onClick={tap}
            sx={{
                color: settingData.lang == props.value ? 'white' : '#999999',
                height: '42px',
                lineHeight: '42px',
                textAlign: 'center',
                borderBottom: 1,
                borderColor: '#5D5A6099',
                fontSize: 16,
                cursor: 'pointer',
            }}>
            {props.title}
        </Typography>
    )
}
