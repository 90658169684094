import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';

export default function TermsJP(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [contentFlag, setContentFlag] = React.useState(2);

    React.useEffect(() => {
        if (props.contentFlag > 0) {
            setContentFlag(props.contentFlag);
        }
    }, []);

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            alignItems: 'center',
        }}>

            <Stack
                onMouseOver={() => {
                    dispatch(settingActions.setMousewheel(false))
                }}
                onMouseLeave={() => {
                    dispatch(settingActions.setMousewheel(true))
                }}
                sx={{
                    width: 1,
                    zIndex: 5,
                    position: 'absolute',
                    height: `${window.innerHeight}px`,
                    overflow: 'scroll',
                    alignItems: 'center',
                }}>

                <Box
                    component={'div'}
                    sx={{
                        color: '#999999',
                        maxWidth: '1000px',
                        py: 3,
                        mx: 1.5,
                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                            ${getContent(contentFlag)}

                            `
                    }} />

            </Stack>

        </Stack>
    );
}

function getContent(flag) {
    if (flag == 1) {
        return getJpStr1();
    } else if (flag == 2) {
        return getJpStr2();
    }
    return '';
}

function getJpStr1() {
    return `

<h1 style="text-align: center;">資金決済法に基づく表示</h1>
<p style="text-align: center;">制定：2023年8月15日</p>
<p>&nbsp;</p>
<h2>1.発行事業者</h2>
<p>A PLUS JAPAN株式会社</p>
<p>&nbsp;</p>
<h2>2.支払可能金額等</h2>
<p>16歳未満　１か月5,000円まで　</p>
<p>16歳～17歳　１か月30,000円まで</p>
<p>18歳以上　1か月　制限なし</p>
<p>&nbsp;</p>
<h2>3.有効期間</h2>
<p>有効期間はありません。</p>
<p>ただし、最終の利用から１年間以上経過しているアカウントを、あらかじめお客様に通知することなく削除することができます。</p>
<p>この場合、未使用のゲーム内通貨は失効します。</p>
<p>&nbsp;</p>
<h2>4.ご相談窓口</h2>
<p>メール:sfd_support@aplusjapan-game.com　</p>
<p>住所:　A PLUS JAPAN株式会社</p>
<p>〒105-6390</p>
<p>東京都港区虎ノ門1-23-1 虎ノ門ヒルズ 森タワー8F</p>
<p>&nbsp;</p>
<h2>5.使用場所</h2>
<p>発行事業者提供のゲームアプリ「ストリートファイター: デュエル」にて使用することができます。</p>
<p>&nbsp;</p>
<h2>6.利用上の注意</h2>
<p>法令に定める場合を除き、払戻しはいたしません。</p>
<p>&nbsp;</p>
<h2>7.利用者保護措置</h2>
<p>(1)前払式支払手段の発行者は、お客様保護のための制度として、資金決済に関する法律の規定に基づき、前払式支払手段の毎年3月31日および9月30日現在の未使用残高の半額以上の額の発行保証金を法務局等に供託等することにより資産保全することが義務づけられています。</p>
<p>(2)販売事業者は、当該発行保証金を【東京法務局に供託する方法により（供託以外の保証金納付方法を採用する場合は記載内容を調整してください）】、当該義務を履行しています。</p>
<p>(3)発行保証金について、お客様は、他の債権者に先立ち弁済を受ける権利を有します。</p>
<p>(4)不正アクセスにより利用者に損失が発生した場合であっても、販売事業者は、何ら責任を負いません。</p>
<p>&nbsp;</p>
<h2>8.その他の事項</h2>
<p>発行事業者の利用規約をご覧ください。</p>
   
    `
}


function getJpStr2() {
    return `

<h1 class="title_policy" style="text-align: center;">特定商取引法に基づく表示</h1>
<p class="title_policy" style="text-align: center;">制定：2023年8月15日</p>
<p>&nbsp;</p>
<h2 class="t2">1.販売事業者の名称・所在地</h2>
<p>A PLUS JAPAN株式会社</p>
<p>代表取締役 孫 潮磊</p>
<p>住所: A PLUS JAPAN株式会社</p>
<p>〒105-6390</p>
<p>東京都港区虎ノ門1-23-1 虎ノ門ヒルズ 森タワー8F</p>
<p>&nbsp;</p>
<h2 class="t2">2.お問い合わせ先</h2>
<p>メール:&nbsp;<a style="color: #999999" href="mailto:sfd_support@aplusjapan-game.com">sfd_support@aplusjapan-game.com</a></p>
<p>&nbsp;</p>
<h2 class="t2">3.販売価格</h2>
<p>購入手続きの際に画面に表示されます。</p>
<p>&nbsp;</p>
<h2 class="t2">4.販売価格以外でお客様が負担する費用</h2>
<p>当サイトのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金等はお客様の負担となります。</p>
<p>&nbsp;</p>
<h2 class="t2">5.申込期間</h2>
<p>申込期間の制限がある場合、購入手続画面等に表示されます。</p>
<p>&nbsp;</p>
<h2 class="t2">6.引渡時期</h2>
<p>購入に関するページに特別な定めがない限り、購入取引完了後、直ちにご利用いただけます。</p>
<p>&nbsp;</p>
<h2 class="t2">7.お支払い方法</h2>
<p>ご利用の端末によって異なります。</p>
<p>iPhoneをご利用の方：AppStoreでのお支払いになります。</p>
<p>Androidをご利用の方：Googleウォレットでのお支払いになります。</p>
<p>&nbsp;</p>
<h2 class="t2">8.支払時期</h2>
<p>購入時にお支払いいただきます。</p>
<p>&nbsp;</p>
<h2 class="t2">9.キャンセル</h2>
<p>購入後のお客様のご都合によるキャンセルは、お受けできません。</p>
<p>&nbsp;</p>
<h2 class="t2">10.特別条件</h2>
<p>16歳未満 １か月5,000円まで</p>
<p>16歳～17歳 １か月30,000円まで</p>
<p>18歳以上 1か月 制限なし</p>
<p>&nbsp;</p>
<h2 class="t2">11.動作環境</h2>
<p>各端末の該当ページに表示しています。</p>
<p>&nbsp;</p>
    
    `
}