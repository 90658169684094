import * as React from 'react';
import { Box, Stack, Typography, Dialog } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import $ from 'jquery';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import * as StringTool from '../store/StringTool';
import * as rdd from 'react-device-detect';

export default function GiftCodeView(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [showHint, setShowHint] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false);
    const [toastMsg, setToastMsg] = React.useState('');

    React.useState(() => {
        dispatch(settingActions.setIsMobile(isMobile()));
        updateCodeData();
    }, []);

    function isMobile() {
        return rdd.isMobile || window.innerWidth < 700;
      }

    function updateCodeData() {
        dispatch(settingActions.updateCodeData());
    }

    function closeTap() {
        dispatch(settingActions.showRegisterAlert(false));
    }

    function submitTap() {
        const ID = $('#input_id')[0].value;
        const code = $('#input_code')[0].value;
        const verify = $('#input_verify')[0].value;

        if (StringTool.isEmpty(ID)) {
            toastShow(settingData.langDic['msg6']);
            return;
        }
        if (StringTool.isEmpty(code)) {
            toastShow(settingData.langDic['msg4']);
            return;
        }
        if (StringTool.isEmpty(verify)) {
            toastShow(settingData.langDic['msg5']);
            return;
        }

        let data = {
            game_id: ID,
            exchange_code: code,
            verify_code: verify,
            verify_cid: settingObj.verify_cid,
        }
        let json = JSON.stringify(data);

        try {
            dispatch(settingActions.showLoading(true));
            $.ajax({
                type: "POST",
                url: StringTool.getAPIHost() + "/1/gift/charge?lang=" + StringTool.getAPILang(),
                contentType: "application/json; charset=utf-8",
                data: json,
                dataType: "json",
                beforeSend: function () {

                },
                complete: function () {

                },
                success: function (res) {
                    dispatch(settingActions.showLoading(false));
                    updateCodeData();

                    if (res == null || res == NaN) {
                        toastShow(settingData.langDic['msg14']);

                    } else if (res.code == 0) { //成功
                        $('#input_id')[0].value = '';
                        $('#input_code')[0].value = '';
                        $('#input_verify')[0].value = '';
                        toastShow(settingData.langDic['msg7']);

                    } else if (res.code == -1001) {
                        toastShow(settingData.langDic['msg13']);

                    } else if (res.code == -1 || res.code == 4 || res.code == 5 || res.code == 102) {
                        toastShow(settingData.langDic['msg11']);

                    } else if (res.code == 6) {
                        toastShow(settingData.langDic['msg12']);

                    } else {
                        toastShow(settingData.langDic['msg14']);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    dispatch(settingActions.showLoading(false));
                    toastShow(settingData.langDic['msg14']);
                    updateCodeData();
                }
            });

        } catch (e) {
            dispatch(settingActions.showLoading(false));
            toastShow(settingData.langDic['msg14']);
            updateCodeData();
        }
    }

    function hintShow() {
        setShowHint(true);
    }

    function hintClose() {
        setShowHint(false);
    }

    function toastShow(msg) {
        setToastMsg(msg);
        setShowToast(true);
    }

    function toastClose() {
        setShowToast(false);
    }

    return (
        <Stack
            sx={{
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'black',
                width: settingData.isMobile ? 1 : '700px',
                pb: 6,
            }} >

            <Box
                component={'img'}
                onClick={closeTap}
                src={getImgPath('close1.png')}
                sx={{
                    visibility: props.hideClose ? 'hidden' : 'show',
                    alignSelf: 'flex-end',
                    cursor: 'pointer',
                    width: '20px',
                    mt: 3,
                    mr: 3,
                    mb: 2,
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'rotate(180deg)',
                    }
                }} />

            <Typography sx={{
                fontSize: settingData.isMobile ? 22 : 35,
                fontWeight: 'bold',
                color: '#C4453B',
                textAlign: 'center'
            }}>
                {settingData.langDic['msg0']}
            </Typography>

            <Stack sx={{
                width: settingData.isMobile ? 0.9 : 0.7,
            }}>
                <Typography sx={{
                    fontSize: settingData.isMobile ? 16 : 20,
                    fontWeight: 'bold',
                    color: 'white',
                    mt: 3,
                }}>
                    {settingData.langDic['msg1']}
                </Typography>
                <Stack sx={{
                    flexDirection: 'row',
                    width: 1,
                    alignItems: 'center',
                }}>
                    <InputItem id='input_id' placeholder={settingData.langDic['msg6']} />
                    <Box
                        onClick={hintShow}
                        component={'img'}
                        src={getImgPath('gray_wen.png')}
                        sx={{
                            height: settingData.isMobile ? '25px' : '38px',
                            ml: 1,
                            cursor: 'pointer'
                        }} />
                </Stack>

                <Typography sx={{
                    fontSize: settingData.isMobile ? 16 : 20,
                    fontWeight: 'bold',
                    color: 'white',
                    mt: 3,
                }}>
                    {settingData.langDic['msg3']}
                </Typography>
                <InputItem id='input_code' placeholder={settingData.langDic['msg4']} />

                <Stack sx={{
                    flexDirection: 'row',
                    width: 1,
                    mt: 2,
                }}>
                    <InputItem id='input_verify' placeholder={settingData.langDic['msg5']} />
                    <Box
                        onClick={updateCodeData}
                        id='verify_image'
                        component={'img'}
                        sx={{
                            cursor: 'pointer',
                            mt: 1,
                            ml: 2,
                            height: '50px',
                            width: '180px',
                        }} />
                </Stack>


                <Typography
                    onClick={submitTap}
                    sx={{
                        fontSize: settingData.isMobile ? 20 : 23,
                        fontWeight: 'bold',
                        color: 'black',
                        mt: 3,
                        bgcolor: '#C4453B',
                        textAlign: 'center',
                        height: '50px',
                        lineHeight: '50px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        userSelect: 'none',
                    }}>
                    {settingData.langDic['msg15']}
                </Typography>
            </Stack>

            <HintItem showHint={showHint} hintClose={hintClose} />
            <ToastItem showToast={showToast} toastClose={toastClose} toastMsg={toastMsg} />

        </Stack>


    );
}

function InputItem(props) {

    return (
        <Stack sx={{
            flexDirection: 'row',
            alignItems: 'center',
            height: '50px',
            borderRadius: '6px',
            overflow: 'hidden',
            bgcolor: '#323232',
            my: 1,
            width: 1,
        }}>

            <Stack
                id={props.id}
                component={'input'}
                type={props.type == 'password' ? 'password' : (props.type == 'code' ? 'number' : 'email')}
                placeholder={props.placeholder}
                sx={{
                    px: 2,
                    fontSize: 16,
                    border: 0,
                    bgcolor: 'transparent',
                    outline: 'none',
                    color: 'white',
                    flex: 1,
                    '&::placeholder': {
                        color: "gray",
                        fontWeight: 'bold',
                        fontFamily: '-moz-initial',
                    },
                }} />
        </Stack>
    );
}

function HintItem(props) {
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack
            onClick={props.hintClose}
            sx={{
                display: props.showHint ? 'flex' : 'none',
                position: 'absolute',
                width: 1,
                height: 1,
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#000000aa'
            }}>

            <Box
                component={'div'}
                sx={{
                    color: 'black',
                    bgcolor: '#9F9F9F',
                    py: 1,
                    px: 3,
                    fontSize: 16,
                    fontWeight: 'bold',
                    borderRadius: '10px',
                }}
                dangerouslySetInnerHTML={{
                    __html:
                        `
                       ${settingData.langDic['msg2']}
                        `
                }} />
        </Stack>
    );
}

function ToastItem(props) {
    return (
        <Stack
            sx={{
                display: props.showToast ? 'flex' : 'none',
                position: 'absolute',
                width: 1,
                height: 1,
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#000000aa',
            }}>

            <Stack sx={{
                color: 'black',
                bgcolor: '#9F9F9F',
                fontSize: 20,
                fontWeight: 'bold',
                borderRadius: '10px',
                textAlign: 'center',
                py: 4,
                px: 3,
                minWidth: '300px',
                alignItems: 'center',
            }}>

                <Box
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        ${props.toastMsg} 
                        `
                    }} />

                <Typography
                    onClick={props.toastClose}
                    sx={{
                        bgcolor: 'black',
                        width: '120px',
                        height: '45px',
                        borderRadius: '8px',
                        color: 'white',
                        lineHeight: '45px',
                        fontSize: 16,
                        mt: 3,
                        cursor: 'pointer',
                    }}>
                    OK
                </Typography>

            </Stack>
        </Stack>
    );
}