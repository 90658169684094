import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import HeroJson from '../store/hero.json';


export default function CharacterAllDetailMobile() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [mbt, setMbt] = React.useState(8);

    React.useEffect(() => {


    }, []);


    function backTap() {
        dispatch(settingActions.setCharacterWrapperIndex(1));
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            alignItems: 'center',
        }}>
            <Box
                component={'img'}
                src={getImgPath('part5/skin_orange.jpg')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: 2,
                    objectFit: 'cover',
                }} />

            <Box
                component={'img'}
                src={getImgPath('part5/style_orange.png')}
                sx={{
                    position: 'absolute',
                    width: 0.9,
                    zIndex: 2,
                    top: '130px',
                    objectFit: 'contain',
                }} />


            {/* 导航 */}
            <Stack sx={{
                position: 'absolute',
                width: 1,
                top: '51px',
                zIndex: 5,
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <Box sx={{
                    width: 1,
                    height: '1px',
                    bgcolor: '#4B1515',
                    bottom: 0,
                    position: 'absolute',
                    zIndex: 3,
                }} />

                <Box
                    onClick={backTap}
                    component={'img'}
                    src={getImgPath('ico_back.png')}
                    sx={{
                        ml: 3,
                        my: 2,
                        width: '35px',
                        height: '35px',
                    }} />

                <Typography sx={{
                    color: '#B32228',
                    ml: 1,
                    fontSize: 18,
                    fontWeight: 'bold',
                }}>
                    {HeroJson[settingData.detailName][settingData.lang]['name']}
                </Typography>
            </Stack>

            {/* 大图 */}
            <Box
                component={'img'}
                src={getImgPath(`all/${settingData.detailName}_big.png`)}
                sx={{
                    position: 'absolute',
                    width: '180%',
                    zIndex: 3,
                    top: "80px",
                    objectFit: 'contain',
                }} />

            {/* 左边 */}
            <Stack sx={{
                position: 'absolute',
                zIndex: 3,
                width: 1,
                height: 1,
                justifyContent: 'flex-end',
                display: 'flex',

            }}>

                <Stack sx={{
                    ml: 3,
                }}>
                    <NameItem />
                    <InfoItem title={settingData.langDic['style']} detail={HeroJson[settingData.detailName][settingData.lang]['style'].toUpperCase()} />
                    <InfoItem title={settingData.langDic['birthday']} detail={HeroJson[settingData.detailName][settingData.lang]['birthday'].toUpperCase()} />
                    <InfoItem title={settingData.langDic['height']} detail={HeroJson[settingData.detailName][settingData.lang]['height'].toLowerCase()} />
                </Stack>


                <Stack sx={{
                    flexDirection: 'row',
                    mt: 3,
                    justifyContent: 'flex-end',
                    mb: mbt,
                    mr: 2,
                }}>
                    <Box
                        component={'img'}
                        src={getImgPath(`all/${settingData.detailName}_small.png`)}
                        sx={{
                            height: '50px',
                            mr: 1,
                            backgroundSize: '100% 100%',
                            backgroundImage: `url(${getImgPath('skil_super_bg.png')})`,
                        }} />

                    {
                        HeroJson[settingData.detailName]['skill_num'].map((value, i) => {
                            return <Box
                                key={i}
                                component={'img'}
                                src={getImgPath(`all/${settingData.detailName}_skill${i}.png`)}
                                sx={{
                                    width: '45px',
                                    height: '45px',
                                    mx: 1,
                                    borderRadius: '28px',
                                    border: 1,
                                    borderWidth: '2px',
                                    borderColor: '#00000066'
                                }} />
                        })
                    }

                </Stack>

            </Stack>

        </Stack>
    );
}

function NameItem(props) {
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            flexDirection: 'row',
            alignItems: 'center',
            mb: 1,
        }}>
            <Stack sx={{
                position: 'relative',
            }}>
                <Typography sx={{
                    fontSize: 33,
                    fontWeight: 'bold',
                    color: 'white',
                    px: 1,
                }}>
                    {HeroJson[settingData.detailName][settingData.lang]['name']}
                </Typography>

                <Box sx={{
                    position: 'absolute',
                    height: '10px',
                    width: 1,
                    bgcolor: '#D77C37',
                    bottom: '2px',
                }} />

            </Stack>

            <Box
                component={'img'}
                src={getImgPath(`type/${HeroJson[settingData.detailName]['type']}.png`)}
                sx={{
                    ml: 3,
                    width: '40px',
                    height: '40px',
                    borderRadius: '38px',
                    border: 1,
                    borderWidth: '2px',
                    borderColor: '#00000066'
                }} />

        </Stack>
    );
}

function InfoItem(props) {
    return (
        <Stack sx={{
            flexDirection: 'row',
            mt: '5px',
        }}>

            <Typography sx={{
                fontSize: 9,
                fontWeight: 'bold',
                color: '#C1BDBB',
                bgcolor: '#00000066',
                width: '80px',
                textAlign: 'end',
                py: '3px',
                pr: '8px',
                mr: '4px',
            }}>
                {props.title}
            </Typography>

            <Typography sx={{
                fontSize: 9,
                fontWeight: 'bold',
                color: '#C1BDBB',
                bgcolor: '#000000bb',
                py: '3px',
                px: '8px',

            }}>
                {props.detail}
            </Typography>

        </Stack>
    );
}