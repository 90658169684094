import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as StringTool from '../store/StringTool';

export default function PaymentButton(props) {
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack
            onClick={() => {
                window.open('https://payment.aplus-games.com/paymentList?gameId=sfd')
            }}
            sx={{
                display: settingData.region == 'en' ? 'flex' : 'none',
                width: '180px',
                height: '53px',
                bgcolor: 'black',
                border: 1,
                borderColor: 'white',
                borderRadius: '6px',
                mr: 2,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                position: 'relative',
                ml: settingData.isMobile && 2,
                mt: settingData.isMobile && 1,
                cursor: 'pointer',
                transitionDuration: '0.3s',
                '&:hover': {
                    transform: 'scale(1.1)',
                },
            }}>

            <Typography sx={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: 24,
                ml: 1.5
            }}>
                {settingData.lang == 'cn' ? "官网充值" : (settingData.lang == 'tw' ? "官網充值" : "TOP-UP")}
            </Typography>

            <Box
                component={'img'}
                src={getImgPath("sale_tag.png")}
                sx={{
                    height: '30px',
                    position: 'absolute',
                    top: 2,
                    right: 5,
                }} />

        </Stack>
    );

}