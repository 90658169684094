import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import HeroJson from '../store/hero.json';
import PageFooterMobile from '../component/PageFooterMobile';


export default function CharacterPageMobile(props) {
    return (
        <Stack sx={{
            height: window.innerHeight,
            width: window.innerWidth,
            bgcolor: 'black',
            overflowY: props.showFooter && 'scroll',
            overflowX: 'hidden',
        }}>
            <Stack>
                <MainView />
                {props.showFooter && <PageFooterMobile noAbaolute={true} />}
            </Stack>
        </Stack>
    );
}

function MainView(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [mbt, setMbt] = React.useState(6);

    return (
        <Stack sx={{
            width: 1,
            height: window.innerHeight,
            alignItems: 'center',
            position: 'relative',
        }}>
            <Box
                component={'img'}
                src={getImgPath('part5/skin_blue.jpg')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: 2,
                    objectFit: 'fill',
                }} />

            <Box
                component={'img'}
                src={getImgPath('part5/style_blue.png')}
                sx={{
                    position: 'absolute',
                    width: 0.9,
                    maxHeight: '280px',
                    zIndex: 2,
                    top: '70px',
                    objectFit: 'contain',
                }} />

            <Box
                component={'img'}
                src={getImgPath(`all/${settingData.characterName}_big.png`)}
                sx={{
                    position: 'absolute',
                    width: '150%',
                    zIndex: 2,
                    top: window.innerHeight * 0.08,
                    objectFit: 'contain',
                }} />

            <Stack sx={{
                position: 'absolute',
                zIndex: 3,
                width: 1,
                height: 1,
                justifyContent: 'flex-end',
            }}>

                {/* 上边 */}
                <Stack sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>

                    <Stack sx={{
                        ml: 2,
                    }}>
                        <NameItem />
                        <InfoItem title={settingData.langDic['style']} detail={HeroJson[settingData.characterName][settingData.lang]['style'].toUpperCase()} />
                        <InfoItem title={settingData.langDic['birthday']} detail={HeroJson[settingData.characterName][settingData.lang]['birthday'].toUpperCase()} />
                        <InfoItem title={settingData.langDic['height']} detail={HeroJson[settingData.characterName][settingData.lang]['height'].toLowerCase()} />
                    </Stack>
                    <Box sx={{ flex: 1 }} />

                    <Stack sx={{
                        width: HeroJson[settingData.characterName]['skill_num'].length == 2 ? '200px' : '150px',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end',
                        mr: 1,
                        mt: 3,
                        position: 'absolute',
                        right: 0,
                    }}>
                        <Box
                            component={'img'}
                            src={getImgPath(`all/${settingData.characterName}_small.png`)}
                            sx={{
                                height: '45px',
                                backgroundSize: '100% 100%',
                                backgroundImage: `url(${getImgPath('skil_super_bg.png')})`,
                            }} />

                        {
                            HeroJson[settingData.characterName]['skill_num'].map((value, i) => {
                                return <Box
                                    key={i}
                                    component={'img'}
                                    src={getImgPath(`all/${settingData.characterName}_skill${i}.png`)}
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '23px',
                                        border: 1,
                                        mx: '4px',
                                        my: '4px',
                                        borderWidth: '3px',
                                        borderColor: '#00000066'
                                    }} />
                            })
                        }
                    </Stack>

                </Stack>

                {/* 下边 */}
                <Stack sx={{
                    width: 1,
                    alignItems: 'center',
                    flexDirection: 'row',
                    overflow: 'scroll',
                    mb: mbt,
                    mt: 2,
                }}>
                    <RoleItem name='Chun-Li' />
                    <RoleItem name='Ken' />
                    <RoleItem name='Ryu' />
                    <RoleItem name='Cammy' />
                    <RoleItem name='Guile' />
                    <RoleItem name='Blanka' />
                    <MoreItem />
                </Stack>

            </Stack>
        </Stack>
    );
}

function MoreItem(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function tap() {
        dispatch(settingActions.setCharacterWrapperIndex(1));
    }

    return (
        <Typography
            onClick={tap}
            sx={{
                minWidth: '75px',
                height: '120px',
                lineHeight: '120px',
                fontWeight: 'bold',
                m: '4px',
                transform: 'skew(-20deg)',
                overflow: 'hidden',
                border: 2,
                bgcolor: '#00000099',
                cursor: 'pointer',
                textAlign: 'center',
                borderColor: '#8E999E',
                color: '#8E999E',
                transitionDuration: '0.3s',
                fontSize: 13,
                '&:hover': {
                    color: '#D92D32',
                    borderColor: '#D92D32',
                },
            }}>
            MORE
        </Typography>
    );
}

function RoleItem(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function tap() {
        dispatch(settingActions.setCharacterName(props.name));
    }

    return (
        <Stack
            onClick={tap}
            sx={{
                bgcolor: '#00000099',
                minWidth: '75px',
                height: '120px',
                ml: props.name == 'Chun-Li' ? '20px' : '5px',
                transform: 'skew(-20deg)',
                overflow: 'hidden',
                border: 2,
                borderColor: settingData.characterName == props.name ? 'white' : '#8E999E',
            }}>
            <Box
                component={'img'}
                src={getImgPath(`all/${props.name}.png`)}
                sx={{
                    WebkitFilter: settingData.characterName == props.name ? 'brightness(100%)' : 'brightness(50%)',
                    filter: settingData.characterName == props.name ? 'brightness(100%)' : 'brightness(50%)',
                    cursor: 'pointer',
                    transform: 'skew(20deg)',
                    width: '135px',
                    height: 1,
                    ml: '-30px',
                    overflow: 'hidden',
                    objectFit: 'contain',
                }} />

            <Typography sx={{
                transform: 'skew(20deg)',
                position: 'absolute',
                bottom: 0,
                color: settingData.characterName == props.name ? 'white' : '#999999',
                backgroundSize: '100% 100%',
                fontSize: 11,
                width: 0.8,
                left: '-3px',
                pl: 1.5,
                fontWeight: 'bold',
                backgroundImage: `url(${getImgPath('part5/i_icon_namebg.png')})`,
            }}>
                {HeroJson[props.name][settingData.lang]['name']}
            </Typography>

        </Stack>
    );
}

function NameItem(props) {

    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            mb: 1,
            flexDirection: 'row',
        }}>
            <Stack sx={{
                position: 'relative',
            }}>
                <Typography sx={{
                    fontSize: 33,
                    fontWeight: 'bold',
                    color: 'white',
                    px: 1,
                }}>
                    {HeroJson[settingData.characterName][settingData.lang]['name']}
                </Typography>

                <Box sx={{
                    position: 'absolute',
                    height: '10px',
                    width: 1,
                    bgcolor: '#328ED7',
                    bottom: '5px',
                }} />

            </Stack>

        </Stack>
    );
}

function InfoItem(props) {
    return (
        <Stack sx={{
            flexDirection: 'row',
            mt: '5px',
        }}>

            <Typography sx={{
                fontSize: 9,
                fontWeight: 'bold',
                color: '#C1BDBB',
                bgcolor: '#00000066',
                width: '70px',
                textAlign: 'end',
                py: '3px',
                pr: '8px',
                mr: '4px',
            }}>
                {props.title}
            </Typography>

            <Typography sx={{
                fontSize: 9,
                fontWeight: 'bold',
                color: '#C1BDBB',
                bgcolor: '#000000bb',
                py: '3px',
                px: '8px',

            }}>
                {props.detail}
            </Typography>

        </Stack>
    );
}