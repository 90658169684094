import * as React from 'react';
import { Box, Stack, Typography, Drawer } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel } from 'swiper/modules';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from './store/SettingSlice';
import $ from 'jquery';

import HomePageMobile from './mobile/HomePageMobile';
import PreRegisterMobile from './mobile/PreRegisterMobile';
import CharacterPageWrapper from './page/CharacterPageWrapper';
import NavViewMobile from './mobile/NavViewMobile';
import StoryPage from './page/StoryPage';
import ContentPage from './page/ContentPage';
import MobileMenu from './mobile/MobileMenu';
import * as StringTool from './store/StringTool';
import CommunityPage from './page/CommunityPage';
import MobileMenuLanguage from './mobile/MobileMenuLanguage';

// 移动 端
function AppMobile() {

  const dispatch = useDispatch();
  const settingData = useSelector(state => state.settingData);
  const [swiper, setSwiper] = React.useState(null);
  let isProcessing = false;
  let swp = null;

  React.useEffect(() => {
    $(document).on("mousewheel", processScroll);
    dispatch(settingActions.setLanguage(StringTool.getLang()));
    dispatch(settingActions.setRegion(StringTool.getRegion()));
  }, []);


  function processScroll(e) {
    if (isProcessing || !settingObj.mousewheel) {
      return;
    }
    const y = e.originalEvent.deltaY;
    if (y < 0) {
      isProcessing = true;
      if (swp) {
        swp.slidePrev();
      }
    } else if (y > 0) {
      isProcessing = true;
      if (swp) {
        swp.slideNext();
      }
    }

    setTimeout(() => {
      isProcessing = false;
    }, 700);
  }

  function navTap(index) {
    if (swiper) {
      swiper.slideTo(index);
    }
  }

  function closeLanguage(){
    dispatch(settingActions.setShowLanguage(false));
  }

  function closeMenu() {
    dispatch(settingActions.setShowMenu(false));
  }

  function slideTo(index) {
    navTap(index);
  }

  return (
    <Stack sx={{
      width: window.innerWidth,
      height: window.innerHeight,
    }}>

      <Swiper
        direction={'vertical'}
        slidesPerView={1}
        className="mySwiper"
        // mousewheel={settingData.mousewheel}
        // modules={settingData.mousewheel && [Mousewheel]}
        resistanceRatio={0}
        onSwiper={(sp) => {
          swp = sp;
          setSwiper(sp);
        }}

        onActiveIndexChange={(sp) => {
          dispatch(settingActions.setNavIndex(sp.activeIndex));
        }}

        style={{
          width: '100%',
          height: '100%',
        }}>

        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><HomePageMobile /></SwiperSlide>
        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><StoryPage /></SwiperSlide>
        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><CharacterPageWrapper /></SwiperSlide>
        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><ContentPage /></SwiperSlide>
        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><CommunityPage /></SwiperSlide>
      </Swiper>

      <NavViewMobile navTap={navTap} />

      {/* 菜单 */}
      <Drawer
        open={settingData.showMenu}
        onClose={closeMenu}>
        <MobileMenu closeMenu={closeMenu} slideTo={slideTo} />
      </Drawer>

       {/* 语言 */}
       <Drawer
        open={settingData.showLanguage}
        anchor='right'
        onClose={closeLanguage}>
        <MobileMenuLanguage closeLanguage={closeLanguage}  />
      </Drawer>

    </Stack>
  );
}

export default AppMobile;
