import * as React from 'react';
import { Box, Stack, Typography, Drawer } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel } from 'swiper/modules';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import $ from 'jquery';
import * as rdd from 'react-device-detect';

import HomePage from './HomePage';
import PreRegister from './PreRegister';
import CharacterPageWrapper from './CharacterPageWrapper';
import NavView from '../component/NavViw';

import HomePageMobile from '../mobile/HomePageMobile';
import PreRegisterMobile from '../mobile/PreRegisterMobile';
import NavViewMobile from '../mobile/NavViewMobile';
import MobileMenu from '../mobile/MobileMenu';
import * as StringTool from '../store/StringTool';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import MobileMenuLanguage from '../mobile/MobileMenuLanguage';

export default function FighttingPage() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [vHeight, setVHeight] = React.useState(window.innerHeight);
    const [swiper, setSwiper] = React.useState(null);
    let isProcessing = false;
    let swp = null;
    let resizeTimer;
    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        const pt = searchParams.get('adPlatform');
        if (!StringTool.isEmpty(pt)) {
            dispatch(settingActions.setAdPlatform(pt));
        }

        $(document).on("mousewheel", processScroll);
        $(window).on("resize", changeSize);

        dispatch(settingActions.setLanguage(StringTool.getLang()));
        dispatch(settingActions.setRegion(StringTool.getRegion()));
        dispatch(settingActions.setIsMobile(isMobile()));
        dispatch(settingActions.setIsFightting(true));
    }, []);

    function isMobile() {
        return rdd.isMobile || window.innerWidth < 700;
    }


    function changeSize(e) {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            setVHeight(window.innerHeight)
        }, 250);
    }


    function processScroll(e) {
        if (isProcessing || !settingObj.mousewheel) {
            return;
        }
        const y = e.originalEvent.deltaY;
        if (y < 0) {
            isProcessing = true;
            if (swp) {
                swp.slidePrev();
            }
        } else if (y > 0) {
            isProcessing = true;
            if (swp) {
                swp.slideNext();
            }
        }

        setTimeout(() => {
            isProcessing = false;
        }, 700);
    }

    function navTap(index) {
        if (swiper) {
            swiper.slideTo(index);
        }
    }

    function closeLanguage() {
        dispatch(settingActions.setShowLanguage(false));
    }

    function closeMenu() {
        dispatch(settingActions.setShowMenu(false));
    }

    function slideTo(index) {
        navTap(index);
    }

    return (
        <Stack>
            {/* PC 端 */}
            {!settingData.isMobile &&
                <Stack sx={{
                    width: 1,
                    height: vHeight,
                    minWidth: '1310px',
                    minHeight: '700px',
                }}>

                    <Swiper
                        direction={'vertical'}
                        slidesPerView={1}
                        className="mySwiper"
                        resistanceRatio={0}
                        onSwiper={(sp) => {
                            swp = sp;
                            setSwiper(sp);
                        }}

                        onActiveIndexChange={(sp) => {
                            dispatch(settingActions.setNavIndex(sp.activeIndex));
                        }}

                        style={{
                            width: '100%',
                            height: '100%',
                        }}>

                        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><HomePage /></SwiperSlide>
                        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><CharacterPageWrapper showFooter={true} /></SwiperSlide>
                    </Swiper>

                    <NavView navTap={navTap} isFightting={true} />
                </Stack>
            }

            {/* 移动端 */}
            {settingData.isMobile &&
                <Stack sx={{
                    width: 1,
                    height: window.innerHeight,
                }}>

                    <Swiper
                        direction={'vertical'}
                        slidesPerView={1}
                        className="mySwiper"
                        // mousewheel={settingData.mousewheel}
                        // modules={settingData.mousewheel && [Mousewheel]}
                        resistanceRatio={0}
                        onSwiper={(sp) => {
                            swp = sp;
                            setSwiper(sp);
                        }}

                        onActiveIndexChange={(sp) => {
                            dispatch(settingActions.setNavIndex(sp.activeIndex));
                        }}

                        style={{
                            width: '100%',
                            height: '100%',
                        }}>

                        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><HomePageMobile /></SwiperSlide>
                        <SwiperSlide style={{ overflow: 'hidden', width: '100%', height: '100%' }}><CharacterPageWrapper showFooter={true} /></SwiperSlide>
                    </Swiper>

                    <NavViewMobile navTap={navTap} />

                    {/* 菜单 */}
                    <Drawer
                        open={settingData.showMenu}
                        onClose={closeMenu}>
                        <MobileMenu closeMenu={closeMenu} slideTo={slideTo} isFightting={true} />
                    </Drawer>

                    {/* 语言 */}
                    <Drawer
                        open={settingData.showLanguage}
                        anchor='right'
                        onClose={closeLanguage}>
                        <MobileMenuLanguage closeLanguage={closeLanguage} />
                    </Drawer>
                </Stack>
            }
        </Stack>
    );
}
