import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import HeroJson from '../store/hero.json';
import PageFooter from '../component/PageFooter';


export default function CharacterPage(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            alignItems: 'center',
        }}>
            <Box
                component={'img'}
                src={getImgPath('part5/skin_blue.jpg')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    zIndex: 2,
                    objectFit: 'fill',
                }} />

            <Box
                component={'img'}
                src={getImgPath('part5/style_blue.png')}
                sx={{
                    position: 'absolute',
                    width: 0.85,
                    maxHeight: '280px',
                    zIndex: 2,
                    top: '90px',
                    objectFit: 'contain',
                }} />

            <Box
                component={'img'}
                src={getImgPath(`all/${settingData.characterName}_big.png`)}
                sx={{
                    position: 'absolute',
                    height: 1,
                    zIndex: 2,
                    objectFit: 'contain',
                }} />

            {/* 左边 */}
            <Stack sx={{
                zIndex: 4,
                position: 'absolute',
                left: '60px',
                bottom: '110px',
                mb: 2,
            }}>
                <NameItem />
                <InfoItem title={settingData.langDic['style']} detail={HeroJson[settingData.characterName][settingData.lang]['style'].toUpperCase()} />
                <InfoItem title={settingData.langDic['birthday']} detail={HeroJson[settingData.characterName][settingData.lang]['birthday'].toUpperCase()} />
                <InfoItem title={settingData.langDic['height']} detail={HeroJson[settingData.characterName][settingData.lang]['height'].toLowerCase()} />

                <Stack sx={{
                    flexDirection: 'row',
                    mt: 3,
                }}>

                    <Box
                        component={'img'}
                        src={getImgPath(`all/${settingData.characterName}_small.png`)}
                        sx={{
                            height: '70px',
                            mr: 2,
                            backgroundSize: '100% 100%',
                            backgroundImage: `url(${getImgPath('skil_super_bg.png')})`,
                        }} />

                    {
                        HeroJson[settingData.characterName]['skill_num'].map((value, i) => {
                            return <Box
                                key={i}
                                component={'img'}
                                src={getImgPath(`all/${settingData.characterName}_skill${i}.png`)}
                                sx={{
                                    width: '60px',
                                    height: '60px',
                                    mx: 2,
                                    borderRadius: '33px',
                                    border: 1,
                                    borderWidth: '3px',
                                    borderColor: '#00000066'
                                }} />
                        })
                    }

                </Stack>
            </Stack>

            {/* 右边 */}
            <Stack sx={{
                zIndex: 4,
                position: 'absolute',
                right: '50px',
                bottom: '280px',
                flexDirection: 'row',
                width: '400px',
                flexWrap: 'wrap-reverse',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <RoleItem name='Chun-Li' />
                <RoleItem name='Ken' />
                <RoleItem name='Ryu' />
            </Stack>

            <Stack sx={{
                zIndex: 4,
                position: 'absolute',
                right: '50px',
                bottom: '100px',
                flexDirection: 'row',
                width: '500px',
                flexWrap: 'wrap-reverse',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <RoleItem name='Cammy' />
                <RoleItem name='Guile' />
                <RoleItem name='Blanka' />
                <MoreItem />
            </Stack>

           {props.showFooter && <PageFooter/>} 
        </Stack>
    );
}

function MoreItem(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function tap() {
        dispatch(settingActions.setCharacterWrapperIndex(1));
    }

    return (
        <Typography
            onClick={tap}
            sx={{
                width: '100px',
                height: '160px',
                lineHeight: '160px',
                fontWeight: 'bold',
                m: '4px',
                transform: 'skew(-20deg)',
                overflow: 'hidden',
                border: 2,
                bgcolor: '#00000077',
                cursor: 'pointer',
                textAlign: 'center',
                borderColor: '#8E999E',
                color: '#8E999E',
                transitionDuration: '0.3s',
                '&:hover': {
                    color: '#D92D32',
                    borderColor: '#D92D32',
                },
            }}>
            MORE
        </Typography>
    );
}

function RoleItem(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function tap() {
        dispatch(settingActions.setCharacterName(props.name));
    }

    return (
        <Stack
            onClick={tap}
            sx={{
                width: '100px',
                height: '160px',
                m: '4px',
                transform: 'skew(-20deg)',
                overflow: 'hidden',
                border: 2,
                borderColor: settingData.characterName == props.name ? '#D92D32' : '#8E999E',
                transitionDuration: '0.3s',
                '&:hover': {
                    borderColor: '#D92D32',
                },
            }}>
            <Box
                component={'img'}
                src={getImgPath(`all/${props.name}.png`)}
                sx={{
                    WebkitFilter: settingData.characterName == props.name ? 'brightness(100%)' : 'brightness(50%)',
                    filter: settingData.characterName == props.name ? 'brightness(100%)' : 'brightness(50%)',
                    cursor: 'pointer',
                    transform: 'skew(20deg)',
                    width: '180px',
                    height: 1,
                    ml: '-35px',
                    overflow: 'hidden',
                    bgcolor: 'black',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        WebkitFilter: 'brightness(100%)',
                        filter: 'brightness(100%)',
                    },
                }} />

            <Typography sx={{
                transform: 'skew(20deg)',
                position: 'absolute',
                bottom: 0,
                color: settingData.characterName == props.name ? 'white' : '#999999',
                backgroundSize: '100% 100%',
                fontSize: 12,
                width: 0.8,
                left: '-10px',
                pl: 3,
                fontWeight: 'bold',
                backgroundImage: `url(${getImgPath('part5/i_icon_namebg.png')})`,
            }}>
                {HeroJson[props.name][settingData.lang]['name']}
            </Typography>

        </Stack>
    );
}

function NameItem(props) {

    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            zIndex: 3,
            mb: 2,
            flexDirection: 'row',
        }}>
            <Stack sx={{
                position: 'relative',
            }}>
                <Typography sx={{
                    fontSize: 60,
                    fontWeight: 'bold',
                    zIndex: 2,
                    color: 'white',
                    px: 2,
                }}>
                    {HeroJson[settingData.characterName][settingData.lang]['name']}
                </Typography>

                <Box sx={{
                    position: 'absolute',
                    height: '20px',
                    width: 1,
                    bgcolor: '#328ED7',
                    bottom: '5px',
                    zIndex: 1,
                }} />

            </Stack>

        </Stack>
    );
}

function InfoItem(props) {
    return (
        <Stack sx={{
            flexDirection: 'row',
            mt: '5px',
        }}>

            <Typography sx={{
                fontSize: 13,
                fontWeight: 'bold',
                color: '#C1BDBB',
                bgcolor: '#00000066',
                width: '100px',
                textAlign: 'end',
                py: '3px',
                pr: '8px',
                mr: '4px',
            }}>
                {props.title}
            </Typography>

            <Typography sx={{
                fontSize: 13,
                fontWeight: 'bold',
                color: '#C1BDBB',
                bgcolor: '#000000bb',
                py: '3px',
                pl: '10px',
                pr: '15px',

            }}>
                {props.detail}
            </Typography>

        </Stack>
    );
}