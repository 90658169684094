import * as React from 'react';
import { Box, Stack, Typography, Dialog } from '@mui/material';
import { getImgPath } from '../store/StringTool';

import { settingActions } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';

export default function VideoAlert(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function closeTap() {
        dispatch(settingActions.showVideoAlert(false));
    }

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    padding: 0,
                    margin: 0,
                },
            }}
            maxWidth={'1000px'}
            open={settingData.showVideoAlert}>

            <Stack
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }} >

                <Box
                    component={'img'}
                    onClick={closeTap}
                    src={getImgPath('close1.png')}
                    sx={{
                        alignSelf: 'flex-end',
                        cursor: 'pointer',
                        width: '20px',
                        mb: 4,
                        mr: 2,
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'rotate(180deg)',
                        }

                    }} />

                <Box
                    component={'video'}
                    autoPlay={true}
                    controls={true}
                    loop={true}
                    muted={true}
                    src={settingData.region == 'jp' ? getImgPath('video_jp.mp4') : getImgPath('video_all.mp4')}
                    sx={{
                        width: settingData.isMobile ? 1 : '800px',
                        objectFit: 'cover',
                        zIndex: 1,
                    }} />

            </Stack>

        </Dialog>
    );

}