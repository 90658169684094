import * as React from 'react';
import { Box, Stack, Typography, Dialog } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import GiftCodeView from './GiftCodeView';

export default function SubmitEmailAlert(props) {
    const settingData = useSelector(state => state.settingData);
    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: settingData.isMobile ? '#00000099' : 'transparent',
                    boxShadow: 'none',
                    padding: 0,
                    margin: 0,
                    height: settingData.isMobile ? window.innerHeight : 'auto',
                    justifyContent: 'center',
                },
            }}
            maxWidth={'1000px'}
            open={settingData.showRegisterAlert}>

            <GiftCodeView />

        </Dialog>
    );
}
