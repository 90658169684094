import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as StringTool from '../store/StringTool';
import { useNavigate } from "react-router-dom";


export default function PageFooter(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function privacyTap() {
        window.open(`${window.location.origin}/privacy`);
    }

    function termsTap() {
        window.open(`${window.location.origin}/terms`);
    }

    function jpTap0() {
        window.open(`${window.location.origin}/financial`);
    }

    function jpTap1() {
        window.open(`${window.location.origin}/special`);
    }

    return (
        <Stack sx={{
            bgcolor: '#0F1114ee',
            height: '60px',
            position: 'absolute',
            bottom: 0,
            zIndex: 10,
            width: 1,
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            <Box
                component={'img'}
                src={getImgPath('logo_footer1.png')}
                sx={{
                    height: 0.7,
                    ml: 3,
                }} />
            <Box
                component={'img'}
                src={getImgPath('logo_footer2.png')}
                sx={{
                    height: 0.7,
                    ml: 1,
                }} />
            <Box
                component={'img'}
                src={getImgPath('logo_footer3.png')}
                sx={{
                    height: 0.7,
                    ml: 1,
                }} />

            <Box
                component={'img'}
                src={getImgPath('logo_footer4.png')}
                sx={{
                    height: 0.7,
                    ml: 2,
                }} />

            {/* 文字 */}
            <Stack sx={{
                height: 1,
                justifyContent: 'center',
                ml: 1,
                color: '#999999'
            }}>

                <Stack sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>

                    <Typography
                        onClick={privacyTap}
                        sx={{
                            fontSize: 11,
                            cursor: 'pointer',
                            borderColor: '#999999',
                            fontWeight: 'bold',
                            '&:hover': {
                                borderBottom: 1,
                            },
                        }}>
                        {settingData.langDic['bottom0']}
                    </Typography>

                    <Typography sx={{
                        mx: '5px',
                        fontSize: 12,
                    }}>
                        /
                    </Typography>

                    <Typography
                        onClick={termsTap}
                        sx={{
                            fontSize: 11,
                            cursor: 'pointer',
                            borderColor: '#999999',
                            fontWeight: 'bold',
                            '&:hover': {
                                borderBottom: 1,
                            },
                        }}>
                        {settingData.langDic['bottom1']}
                    </Typography>

                    <Typography sx={{
                        mx: '5px',
                        fontSize: 12,
                    }}>
                        /
                    </Typography>

                    {/* 日本多两个 */}
                    {
                        settingData.region == 'jp' &&
                        <Stack sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            <Typography
                                onClick={jpTap0}
                                sx={{
                                    fontSize: 11,
                                    cursor: 'pointer',
                                    borderColor: '#999999',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        borderBottom: 1,
                                    },
                                }}>
                                資金決済法に基づく
                            </Typography>

                            <Typography sx={{
                                mx: '5px',
                                fontSize: 12,
                            }}>
                                /
                            </Typography>

                            <Typography
                                onClick={jpTap1}
                                sx={{
                                    fontSize: 11,
                                    cursor: 'pointer',
                                    borderColor: '#999999',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        borderBottom: 1,
                                    },
                                }}>
                                特定商取引法に基づく
                            </Typography>

                            <Typography sx={{
                                mx: '5px',
                                fontSize: 12,
                            }}>
                                /
                            </Typography>


                        </Stack>
                    }

                    <a style={{
                        color: '#999999',
                        fontSize: 11,
                        fontWeight: 'bold',
                    }} href={`mailto:${settingData.langDic['bottom_email']}`}>{settingData.langDic['bottom2']}</a>

                </Stack>

                <Typography sx={{
                    fontSize: 11,
                    fontWeight: 'bold',
                }}>
                    {settingData.langDic['bottom3']}
                </Typography>
            </Stack>

            {/* 下载按钮 */}
            <Box
                onClick={() => {
                    StringTool.appleTap();
                }}
                component={'img'}
                src={getImgPath(`btns/${settingData.region}_apple_release.png`) }
                sx={{
                    height: 0.7,
                    mr: 1,
                    ml: 3,
                    borderColor: '#cccccc',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                }} />

            <Box
                onClick={() => {
                    StringTool.googleTap();
                }}
                component={'img'}
                src={getImgPath(`btns/${settingData.region}_google_release.png`)}
                sx={{
                    height: 0.7,
                    ml: 1,
                    borderColor: '#cccccc',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                }} />

            <Box
                onClick={() => {
                    StringTool.onestoreTap();
                }}
                component={'img'}
                src={getImgPath(`btns/${settingData.region}_one_release.png`)}
                sx={{
                    display: settingData.region == 'kr' ? 'flex' : 'none',
                    height: 0.7,
                    ml: 2,
                    borderColor: '#cccccc',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                }} />
        </Stack>
    );
}