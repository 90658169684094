import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { settingActions } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getImgPath } from '../store/StringTool';

export default function MobileMenu(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function itemTap(i) {
        props.slideTo(i);
        dispatch(settingActions.setShowMenu(false));
    }

    return (
        <Stack sx={{
            width: window.innerWidth * 0.7,
            height: window.innerHeight,
            bgcolor: 'black',
        }}>

            <Box component={'img'}
                onClick={props.closeMenu}
                src={getImgPath('close_white.png')}
                sx={{
                    width: '25px',
                    mt: 2,
                    ml: 3,
                    mb: 5,
                }} />

            {(!props.isFightting) &&
                <Stack>
                    <Item title={settingData.langDic['menu1']} index={0} itemTap={itemTap} />
                    <Item title={settingData.langDic['menu3']} index={1} itemTap={itemTap} />
                    <Item title={settingData.langDic['menu4']} index={2} itemTap={itemTap} />
                    <Item title={settingData.langDic['menu5']} index={3} itemTap={itemTap} />
                    <Item title={settingData.langDic['menu6']} index={4} itemTap={itemTap} />
                </Stack>
            }

            {(props.isFightting) &&
                <Stack>
                    <Item title={settingData.langDic['menu1']} index={0} itemTap={itemTap} />
                    <Item title={settingData.langDic['menu4']} index={1} itemTap={itemTap} />
                </Stack>
            }
        </Stack>
    );
}

function Item(props) {
    return (
        <Stack onClick={() => props.itemTap(props.index)}>
            <Typography sx={{
                color: 'white',
                fontFamily: 'us',
                fontSize: 18,
                height: '70px',
                lineHeight: '70px',
                pr: 5,
                pl: 5,
                textAlign: 'end',
            }}>
                {props.title}
            </Typography>
            <Box sx={{
                width: 1,
                height: '1px',
                bgcolor: '#C9CDD140',
            }} />
        </Stack>
    );
}