import CharacterPage from './CharacterPage';
import CharacterAllPage from './CharacterAllPage';
import CharacterAllDetail from './CharacterAllDetail';

import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import { Stack } from '@mui/material';

import CharacterPageMobile from '../mobile/CharacterPageMobile';
import CharacterAllPageMobile from '../mobile/CharacterAllPageMobile';
import CharacterAllDetailMobile from '../mobile/CharacterAllDetailMobile';

export default function CharacterPageWrapper(props) {
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack>
            {!settingData.isMobile && <PcWraper showFooter={props.showFooter}/>}
            {settingData.isMobile && <MobileWraper showFooter={props.showFooter}/>}
        </Stack>
    );
}

function PcWraper(props) {
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack>
            {settingData.characterWrapperIndex == 0 && <CharacterPage showFooter={props.showFooter}/>}
            {settingData.characterWrapperIndex == 1 && <CharacterAllPage />}
            {settingData.characterWrapperIndex == 2 && <CharacterAllDetail />}
        </Stack>
    );
}

function MobileWraper(props) {
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack>
            {settingData.characterWrapperIndex == 0 && <CharacterPageMobile showFooter={props.showFooter}/>}
            {settingData.characterWrapperIndex == 1 && <CharacterAllPageMobile />}
            {settingData.characterWrapperIndex == 2 && <CharacterAllDetailMobile />}
        </Stack>
    );
}