import { Backdrop, Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import $ from 'jquery';
import * as rdd from 'react-device-detect';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import * as StringTool from '../store/StringTool';

export default function GameVideo(pros) {

    const settingData = useSelector(state => state.settingData);
    const [vHeight, setVHeight] = React.useState(window.innerHeight);
    let resizeTimer;
    const [index, setIndex] = React.useState(1);
    const [muted, setMuted] = React.useState(false); //静音

    React.useEffect(() => {
        $(window).on('resize', function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                setVHeight(window.innerHeight)
            }, 250);
        });
    }, []);

    function addMeta(tyep, value, type2, value2) {
        const head = document.getElementsByTagName('head').item(0);
        const script = document.createElement('meta');
        script.setAttribute(tyep, value);
        script.setAttribute(type2, value2);
        head.appendChild(script);
    }

    function startPlayTap() {
        setIndex(2);
        $('#game_video').get(0).play();
    }

    function onVideoFinished() {
        setMuted(true);
        setIndex(3);
    }

    function replayTap() {
        setMuted(false);
        setIndex(1);
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
        }}>

            <Stack sx={{
                position: 'relative',
                height: rdd.isMobile ? 1 : `${vHeight}px`,
                maxHeight: '1000px',
                width: (rdd.isMobile ? 1 : 'auto'),
            }}>
                {/* 站位 */}
                <Box
                    component={'img'}
                    src={settingData.region == 'kr' ? getImgPath('gameVideo/welcome_kr.png') : getImgPath('gameVideo/welcome.png')}
                    sx={{
                        height: 1,
                        width: rdd.isMobile ? 1 : 'auto',
                        objectFit: 'fill'
                    }} />

                <WelcomeView index={index} />

                <StartView index={index} startPlayTap={startPlayTap} setMuted={setMuted} muted={muted} />

                <VideoView index={index} setMuted={setMuted} muted={muted} onVideoFinished={onVideoFinished} />

                <FinishView index={index} replayTap={replayTap} />

            </Stack>

        </Stack>
    );
}


function WelcomeView(props) {
    const settingData = useSelector(state => state.settingData);
    return (
        <Stack
            id='index_0'
            sx={{
                display: props.index == 0 ? 'flex' : 'none',
                width: 1,
                height: 1,
                position: 'absolute',
                bgcolor: '#061429',
            }}>
            <Box
                component={'img'}
                src={settingData.region == 'kr' ? getImgPath('gameVideo/welcome_kr.png') : getImgPath('gameVideo/welcome.png')}
                sx={{
                    height: 1,
                    width: 1,
                    objectFit: 'fill',
                    position: 'absolute',
                }} />

        </Stack>
    )
}


function StartView(props) {
    return (
        <Stack
            id='index_1'
            sx={{
                visibility: props.index == 1 ? 'flex' : 'hidden',
                width: 1,
                height: 1,
                position: 'absolute',
                bgcolor: '#000000d0',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Typography sx={{
                textAlign: 'center',
                width: 0.9,
                fontSize: 19,
                fontWeight: 'bold'
            }}>
                Please note that the sound will play when the sound is turned ON.
            </Typography>

            <Stack sx={{
                flexDirection: 'row',
                height: '60px',
                mt: 8,
                mb: 10,
            }}>
                <Box
                    onClick={() => {
                        props.setMuted(false);
                    }}
                    component={'img'}
                    src={props.muted ? getImgPath('gameVideo/on_black.png') : getImgPath('gameVideo/on_white.png')}
                    sx={{
                        height: 1,
                        mr: 5,
                        cursor: 'pointer',
                    }} />

                <Box
                    onClick={() => {
                        props.setMuted(true);
                    }}
                    component={'img'}
                    src={props.muted ? getImgPath('gameVideo/off_white.png') : getImgPath('gameVideo/off_black.png')}
                    sx={{
                        height: 1,
                        cursor: 'pointer',
                    }} />

            </Stack>

            <Typography
                onClick={props.startPlayTap}
                sx={{
                    textAlign: 'center',
                    width: '180px',
                    height: '46px',
                    lineHeight: '46px',
                    fontSize: 16,
                    fontWeight: 'bold',
                    bgcolor: '#F76E40',
                    borderRadius: '23px',
                    cursor: 'pointer',
                }}>
                Start Play
            </Typography>

        </Stack>
    )
}


function VideoView(props) {
    const settingData = useSelector(state => state.settingData);
    const [count, setCount] = React.useState(0);
    const [myTimer, setMyTimer] = React.useState(null);

    React.useEffect(() => {
        $('#game_video').on('ended', () => {
            props.onVideoFinished();
        })
    }, []);

    function musicTap() {
        props.setMuted(!props.muted);
        if (myTimer == null) {
            setMyTimer(setInterval(function () { musicAni() }, 70));
        } else {
            clearInterval(myTimer);
            setMyTimer(null);
        }
    }

    function musicAni() {
        setCount(prevCount => {
            if (prevCount >= 29) {
                return 0;
            }
            return prevCount + 1;
        });
    }

    return (
        <Stack sx={{
            display: props.index == 2 ? 'flex' : 'none',
            width: 1,
            height: 1,
            position: 'absolute',
        }}>
            <Box
                id='game_video'
                component={'video'}
                autoPlay={false}
                loop={false}
                muted={props.muted}
                playsInline={true}
                disablePictureInPicture={true}
                src={settingData.region == 'kr' ? getImgPath('gameVideo/sfd_kr.mp4') : getImgPath('gameVideo/sfd2.mp4')}
                sx={{
                    disablePictureInPicture: true,
                    position: 'absolute',
                    display: props.index == 2 ? 'flex' : 'none',
                    height: 1,
                    width: 1,
                    objectFit: 'fill'
                }} />

            {/* 音乐 */}
            <Box
                component={'img'}
                src={!props.muted ? getImgPath(`gameVideo/music_gif/_000${count}.png`) : getImgPath('gameVideo/music_stop.png')}
                onClick={musicTap}
                sx={{
                    display: props.index == 2 ? 'flex' : 'none',
                    position: 'absolute',
                    right: '10px',
                    top: '45px',
                    cursor: 'pointer',
                    width: '50px',
                    animation: !props.muted ? 'musicRotate 3s linear infinite' : '',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                    }
                }} />
        </Stack>
    );


}


function FinishView(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function downloadTap() {
        if (settingData.region == 'jp') {
            window.open('https://sfdjp.onelink.me/Gg8K/s7iawp0h');
        } else if (settingData.region == 'kr') {
            window.open('https://sfdkr.onelink.me/lUTJ/7e84vhbr');
        } else {
            window.open(window.location.origin);
        }
    }

    return (
        <Stack
            id='index_3'
            sx={{
                display: props.index == 3 ? 'flex' : 'none',
                width: 1,
                height: 1,
                position: 'absolute',
            }}>
            <Box
                component={'img'}
                src={settingData.region == 'kr' ? getImgPath('gameVideo/finish_kr.jpg ') : getImgPath('gameVideo/finish2.png')}
                sx={{
                    height: 1,
                    width: 1,
                    objectFit: 'fill',
                    position: 'absolute'
                }} />

            <Stack sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>

                <Box
                    onClick={() => {
                        downloadTap();
                    }}
                    sx={{
                        width: 0.68,
                        height: '100px',
                        mb: 7,
                        cursor: 'pointer',
                    }} />

                {/* 重看 */}
                <Box
                    component={'img'}
                    src={getImgPath('gameVideo/replayTap.png')}
                    onClick={props.replayTap}
                    sx={{
                        position: 'absolute',
                        right: '20px',
                        top: '30px',
                        cursor: 'pointer',
                        width: '28px',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.1)',
                        }
                    }} />

            </Stack>
        </Stack>
    );
}